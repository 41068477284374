<template><div><h1 id="legacy-boot-mode-alternative-bootloader-altfw" tabindex="-1"><a class="header-anchor" href="#legacy-boot-mode-alternative-bootloader-altfw"><span>Legacy Boot Mode / Alternative Bootloader (AltFw)</span></a></h1>
<p>ChromeOS’ Legacy Boot Mode (old older devices) or Alternative Bootloader (aka AltFw, on newer devices) provides a method for booting an alternative OS by running a firmware payload from the RW_LEGACY firmware region. On older devices using Legacy Boot Mode, RW_LEGACY contains a single payload; on devices with AltFw, there can be multiple (though this feature is not used on most devices)</p>
<ul>
<li>Unsupported (by Google) method for booting alternate OSes using the RW_LEGACY firmware region
<ul>
<li>On older devices, the <a href="http://www.seabios.org/" target="_blank" rel="noopener noreferrer">SeaBIOS</a> payload is used, providing a Legacy BIOS bootloader</li>
<li>On newer devices, the <a href="https://github.com/tianocore/edk2" target="_blank" rel="noopener noreferrer">edk2</a> (aka Tianocore) payload is used, providing a UEFI bootloader.</li>
</ul>
</li>
<li>Accessed via <code v-pre>[CTRL+L]</code> on the developer mode boot screen (older devices) or Alternative Bootloader menu option (newer devices)</li>
<li>Requires explicit enabling in Developer Mode via command line: <code v-pre>sudo crossystem dev_boot_legacy=1 (or dev_boot_altfw=1)</code> (installing RW_LEGACY firmware via the <a href="/docs/fwscript">Firmware Utility Script</a> will set these flags for you)</li>
<li>Most ChromeOS devices are not capable out of the box, and require a RW_LEGACY firmware update first</li>
</ul>
<p><strong>Note:</strong> If you hear two beeps after pressing <code v-pre>[CTRL+L]</code>, then either your device doesn’t have a valid Legacy Boot Mode / RW_LEGACY firmware installed, or legacy boot/AltFw capability has not been been enabled via the crossystem flag(s) above.</p>
<p>Using the ChromeOS Firmware Utility Script to update your device’s RW_LEGACY firmware region will address both of these issues, as it not only provides an updated, fully functional RW_LEGACY firmware, but also sets the required boot flag.</p>
</div></template>


